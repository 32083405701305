import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import closeImg from '../../../images/icons/close.png';
import proImg from '../../../images/icons/pro.svg';
import speedImg from '../../../images/icons/fast_game.svg';
import unlimitedImg from '../../../images/redesign/common/Room types unlimited.svg';
import lightningImg from '../../../images/icons/fast_game_lightning.svg';

import keyImg from '../../../images/icons/private.svg';
import fourPImg from '../../../images/icons/4_spēlētāji.svg';
import minGamesImg from '../../../images/icons/min_games.svg';
import infoImg from '../../../images/icons/info-icon-3.png';

import buttonClickedSound from '../../../sounds/click_feedback.flac';

import {
  toggleNewGameParam, setNewGameBet, setNewMinGamesFunc, setNewGameSpeed,
} from '../../../actions/userSettings';
import { FONT_MODE } from '../../../constants/constants';
import { updateAutoMergeRooms } from '../../../actions/member';

import { size } from 'lodash';

const GameBetMap = {
  // '1:1': '1:1',
  // '1:5': '1:5',
  '1:10': '1:10',
  '1:25': '1:25',
  '1:50': '1:50',
  '1:100': '1:100',
  '1:250': '1:250',
  '1:500': '1:500',
  '1:1000': '1:1000',
  '1:5000': '1:5000',
  '1:10000': '1:10000',
};

const GameSpeedMap = {
  unlimited: 'Unlimited',
  parasta: 'Parasta',
  atra: 'Ātrā',
  lightning: 'Zibensistaba',
};

const MinGamesMap = {
  1: '1',
  3: '3',
  5: '5',
  10: '10',
  15: '15',
  20: '20',
  25: '25',
};

class NewGame extends React.Component {
  static propTypes = {
    createRoom: PropTypes.func.isRequired,
    lvl: PropTypes.number,
    privateRoomPassword: PropTypes.number,
    uid: PropTypes.string,
    activeRoom: PropTypes.string,
    activeTournament: PropTypes.string,
    //  GameBetMap: PropTypes.shape(),
    //  MinGamesMap: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    closePrivateRoomPassword: PropTypes.func.isRequired,
    userSettings: PropTypes.shape({
      parasta: PropTypes.bool,
      G: PropTypes.bool,
      //  atra: PropTypes.bool,
      pro: PropTypes.bool,
      maza: PropTypes.bool,
      privateRoom: PropTypes.bool,
      bet: PropTypes.string,
      minGames: PropTypes.string,
      speed: PropTypes.string,
      fourPRoom: PropTypes.bool,
      soundOn: PropTypes.bool,
    }),
    joinedRoom: PropTypes.shape({
      globalParams: PropTypes.shape({
        bet: PropTypes.string,
        gameType: PropTypes.string,
        smallGame: PropTypes.string,
        privateRoom: PropTypes.bool,
        minGames: PropTypes.string,
        fourPRoom: PropTypes.string,
        fastGame: PropTypes.string,
        proGame: PropTypes.string,
      }),
    }),
    showPrivateRoomPassword: PropTypes.bool,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    toggleNewGameParameter: PropTypes.func.isRequired,
    setNewBet: PropTypes.func.isRequired,
    setNewSpeed: PropTypes.func.isRequired,
    setNewMinGames: PropTypes.func.isRequired,
    resetRoomError: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    myTournamentsData: PropTypes.shape({}),
    offset: PropTypes.number,
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
    setAutoMergeRooms: PropTypes.func.isRequired,
  //  playButtonSound: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lvl: null,
    uid: null,
    activeRoom: null,
    activeTournament: null,
    showPrivateRoomPassword: false,
    userSettings: {},
    joinedRoom: null,
    privateRoomPassword: null,
    fontMode: FONT_MODE.normal,
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      createRoomClicked: false,
      gameBetChoserOpen: false,
      gameSpeedChoserOpen: false,
      minGamesChoserOpen: false,
      popoverOpen: false,
      devMode: false,
    };

    //  this.selectBet = this.selectBet.bind(this);
    //  this.selectMinGames = this.selectMinGames.bind(this);
    //  this.toggleGameBetChoser = this.toggleGameBetChoser.bind(this);
    //  this.toggleMinGamesChoser = this.toggleMinGamesChoser.bind(this);
    //  this.createRoomClicked = this.createRoomClicked.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { hostname } = window.location;

    let devMode = false;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      devMode = true;
    }

    this.setState({ devMode });
  }

  toggleAutoMergeRooms = () => {
    const { autoMergeRooms, setAutoMergeRooms } = this.props;

    if (autoMergeRooms) {
      setAutoMergeRooms(false);
    } else {
      setAutoMergeRooms(true);
    }
  }

  toggleParasta = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('parasta', uid);
  };

  toggleG = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('G', uid);
  };

  /*  toggleAtra = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('atra', uid);
  }; */

  togglePro = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('pro', uid);
  };

  toggleSmall = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('maza', uid);
  };

  togglePrivate = () => {
    const { toggleNewGameParameter, userSettings, uid } = this.props;
    const { privateRoom } = userSettings;

    this.playButtonSound();

    if (privateRoom) {
      this.setState({ privateRoomConfirm: false });
      toggleNewGameParameter('privateRoom', uid);
    } else {
      this.setState({ privateRoomConfirm: true });
    }
  };

  toggleFourPRoom = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('fourPRoom', uid);
  };

  confirmTogglePrivate = () => {
    const { toggleNewGameParameter, uid } = this.props;

    this.playButtonSound();

    toggleNewGameParameter('privateRoom', uid);
    this.setState({ privateRoomConfirm: false });
  };

  selectBet = (newBet) => {
    const { setNewBet, uid } = this.props;

    this.playButtonSound();

    setNewBet(newBet, uid);
  };

  selectSpeed = (newSpeed) => {
    const { setNewSpeed, uid } = this.props;

    this.playButtonSound();

    setNewSpeed(newSpeed, uid);
  }

  selectMinGames = (newMinGames) => {
    const { setNewMinGames, uid } = this.props;

    this.playButtonSound();

    setNewMinGames(newMinGames, uid);
  };

  closePrivateConfirm = () => {
    this.setState({ privateRoomConfirm: false });
  };

  createRoomClicked = async () => {
    const { createRoom, userSettings, activeTournament, finishedTournamentLastRound, lvl, t, setScreenView, autoMergeRooms } = this.props;
    const {
      createRoomClicked, devMode,
    } = this.state;

    if (userSettings.pro && lvl < 10) {
      this.setState({
        createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:needLevelForPro'), errorModalHeader: t('game:message'),
      });
      return null;
    }

    if (activeTournament && !finishedTournamentLastRound) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        createRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    const {
      parasta, G, pro, maza, privateRoom, bet, minGames, fourPRoom, speed,
    } = userSettings;

    if (!createRoomClicked) {
      this.setState({ createRoomClicked: true });

      this.playButtonSound();

      if (privateRoom) {
        await createRoom(parasta, G, null, pro, bet, maza, privateRoom, minGames || '1', fourPRoom, speed, (autoMergeRooms && devMode)).then((res) => {
          console.log('createRoom res', res);
          this.setState({ createRoomClicked: false });
        }).catch((err) => {
          console.log('createRoom err', err);
          this.setState({ createRoomClicked: false });
        });
      } else {
        await createRoom(parasta, G, null, pro, bet, maza, false, minGames || '1', fourPRoom, speed, (autoMergeRooms && devMode)).then((res) => {
          console.log('createRoom res', res);
          this.setState({ createRoomClicked: false });
        }).catch((err) => {
          console.log('createRoom err', err);
          this.setState({ createRoomClicked: false });
        });
      }

      setScreenView('createRoom');
    }

    return null;
  };

  checkTournamentLine = () => {
    const { tournaments, myTournamentsData, offset } = this.props;

    if (size(myTournamentsData) > 0) {
      if (size(tournaments) > 0) {

        if (myTournamentsData.tournamentId) {
          const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);
         
          if(curTournament && curTournament.currentState === 'registration') {
            if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  toggleGameBetChoser = () => {
    this.setState(prevState => ({
      gameBetChoserOpen: !prevState.gameBetChoserOpen,
    }));
  }

  toggleGameSpeedChoser = () => {
    this.setState(prevState => ({
      gameSpeedChoserOpen: !prevState.gameSpeedChoserOpen,
    }));
  }

  toggleMinGamesChoser = () => {
    this.setState(prevState => ({
      minGamesChoserOpen: !prevState.minGamesChoserOpen,
    }));
  }

  onHover = () => {
    const { popoverOpen } = this.state;

    if (!popoverOpen) {
      this.setState({
        popoverOpen: true,
      });
    }
  }

  onHoverLeave = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

    console.log('playButtonSound soundOn', soundOn);

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }

  closeErrorModal = () => {
    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null
    });
  }

  mouseEnterMergePopover = () => {
    this.setState({ mergePopoverActive: true });
  }

  mouseLeaveMergePopover = () => {
    this.setState({ mergePopoverActive: false });
  }

  render() {
    const {
      lvl,
      t,
      privateRoomPassword,
      showPrivateRoomPassword,
      closePrivateRoomPassword,
      userSettings,
      joinedRoom,
      joinedRoomPassword,
      i18n,
      activeRoom,
      activeTournament,
      finishedTournamentLastRound,
      fontMode,
      autoMergeRooms,
    } = this.props;

    // console.log('newgame i18n', i18n);
    // console.log('newgame i18n.getResourceBundle', i18n.getResourceBundle('lv', "common"));
    
    console.log('new game autoMergeRooms', autoMergeRooms);

    const {
      privateRoomConfirm,
      gameBetChoserOpen,
      gameSpeedChoserOpen,
      minGamesChoserOpen,
      popoverOpen,
      devMode,
      errorModalOpen,
      errorModalText,
      errorModalHeader,
      mergePopoverActive,
    } = this.state;

    let digitsArr = [];

    console.log('joinedRoomPassword', joinedRoomPassword, privateRoomPassword);

    if (joinedRoomPassword) {
      const digits = joinedRoomPassword.toString().split('');
      digitsArr = digits.map((number, index) => ({ number, index }));
    }

    return (
      <Row>
        <Col sm="12" className="start-new-game-wrapper">
          {joinedRoom && joinedRoom.globalParams ? (
            <div className="start-new-game">
              <Row>
                <Col sm="12" className="created-room">
                  {t('newGame.activeRoom')}
                </Col>
              </Row>
              <Row>
                <Col sm={{ size: 10, offset: 1 }}>
                  <div className="created-room-div">
                    {joinedRoom.globalParams.bet}
                  </div>
                  <div className="created-room-div">
                    {joinedRoom.globalParams.gameType === 'P' ? (
                      <Fragment>
                        <div className="gameType">
                          {joinedRoom.globalParams.smallGame ? (
                            <p className="gameType-text">PM</p>
                          ) : (
                            <p className="gameType-text">P</p>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="gameType">
                          {joinedRoom.globalParams.smallGame ? (
                            <p className="gameType-text">MG</p>
                          ) : (
                            <p className="gameType-text">G</p>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="created-room-div">
                    {joinedRoom.globalParams.privateRoom && (
                      <Fragment>
                        <Media
                          className="label-private"
                          src={keyImg}
                          alt="Privāta"
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className="created-room-div">
                    {joinedRoom.globalParams.fourPRoom && (
                      <Fragment>
                        <Media
                          className="label-private"
                          src={fourPImg}
                          alt="4 Spēlētāji"
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className="created-room-div">
                    <div className="minGames">
                      <Media className="minGames-img" src={minGamesImg} alt="4 Spēlētāji" />
                      <p className="minGames-text-active-room">
                        {joinedRoom.globalParams.minGames ? (
                          joinedRoom.globalParams.minGames
                        ) : (
                          '1'
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className="created-room-div"
                    style={{ padding: 0, paddingTop: 5, paddingLeft: 10 }}
                  >
                    {joinedRoom.globalParams.fastGame && (
                      <Fragment>
                        <Media className="label-speed" src={speedImg} alt="Ātrā" />
                      </Fragment>
                    )}
                    {joinedRoom.globalParams.lightningGame && (
                      <Fragment>
                        <Media className="label-speed" src={lightningImg} alt="Lightning" />
                      </Fragment>
                    )}
                    {joinedRoom.globalParams.unlimitedGame && (
                      <Fragment>
                        <Media className="label-speed" src={unlimitedImg} alt="Unlimited" />
                      </Fragment>
                    )}
                  </div>
                  <div className="created-room-div">
                    {joinedRoom.globalParams.proGame && (
                      <Fragment>
                        <Media className="label-pro" src={proImg} alt="Pro" />
                      </Fragment>
                    )}
                  </div>

                </Col>
              </Row>
              {digitsArr
              && digitsArr.length
              && joinedRoom.globalParams.privateRoom ? (
                <Fragment>
                  <Row className="created-room-code-title">
                    <Col>{t('newGame.password')}</Col>
                  </Row>
                  <div className="created-room-code">
                    {digitsArr.map(digit => (
                      <div key={`digit-${digit.number}-${digit.index}`} className="room-password-digit">{digit.number}</div>
                    ))}
                  </div>
                </Fragment>
                ) : (
                  <div />
                )}
            </div>
          ) : (
            <div className="start-new-game">
              <Row>
                <Col sm="12" className="new-game">
                  {t('newGame.newGame')}
                </Col>
              </Row>
              <Row>
                <div className="game-type-wrapper">
                  <Row className="game-type-select">
                    <Col sm="12">{t('common.gameType')}</Col>
                  </Row>
                  <Row>
                    <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.toggleParasta}
                          checked={userSettings.parasta}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('roomsTable.regular')}</div>
                      </Label>
                    </Col>
                    <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 13, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.toggleG}
                          checked={userSettings.G}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 10 }} />
                        <div className="game-type-text">{t('roomsTable.table')}</div>
                      </Label>
                    </Col>
                    {/*  <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.toggleAtra}
                          checked={userSettings.atra}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">
                          {t('newGame.fastGame')}
                        </div>
                      </Label>
                    </Col> */}
                    <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.togglePro}
                          checked={userSettings.pro}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">
                          {t('newGame.proGame')}
                        </div>
                      </Label>
                    </Col>

                    <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 13, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.toggleSmall}
                          checked={userSettings.maza}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 10 }} />
                        <div className={`game-type-text ${i18n.language === 'ru' ? 'game-type-text-ru' : null}`}>
                          {t('newGame.smallGame')}
                        </div>
                      </Label>
                    </Col>

                    <Col xs="6" sm="6">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.togglePrivate}
                          checked={userSettings.privateRoom}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('newGame.privateRoom')}</div>
                      </Label>
                    </Col>
                    <Col xs="12" sm="12">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 10 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={this.toggleFourPRoom}
                          checked={userSettings.fourPRoom}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('newGame.fourPRoom')}</div>
                      </Label>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row className="sng-choose-bet">
                <Col>
                  <div className="sng-choose-bet-label">
                    {t('common.speed')}
                  </div>

                  <Dropdown
                    className="small"
                    isOpen={gameSpeedChoserOpen}
                    toggle={this.toggleGameSpeedChoser}
                  >
                    <DropdownToggle caret>
                      {t(`common.${userSettings.speed ? userSettings.speed : 'parasta'}`)}
                    </DropdownToggle>
                      <DropdownMenu>
                        {Object.keys(GameSpeedMap).map(bKey => (
                          <>
                            {(bKey === 'unlimited' && !devMode) ? (
                              null
                            ) : (
                              <DropdownItem
                                key={bKey}
                                onClick={() => {
                                  this.selectSpeed(bKey);
                                }}
                              >
                                {t(`common.${bKey}`)}
                              </DropdownItem>
                            )}
                          </>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              <Row className="sng-choose-bet">
                <Col>
                  <div className="sng-choose-bet-label">
                    {t('common.bet')}
                  </div>

                  <Dropdown
                    className="small"
                    isOpen={gameBetChoserOpen}
                    toggle={this.toggleGameBetChoser}
                  >
                    <DropdownToggle caret>
                      {GameBetMap[userSettings.bet]}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(GameBetMap).map((bKey) => {
                        if (lvl && lvl >= 3 && (bKey === '1:1' || bKey === '1:5')) {
                          return null;
                        }
                        return (
                          <DropdownItem
                            key={bKey}
                            onClick={() => {
                              this.selectBet(bKey);
                            }}
                          >
                            {GameBetMap[bKey]}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              <Row className="sng-choose-bet">
                <Col>
                  <div className="sng-choose-bet-label">
                    {t('common.minGames')}
                  </div>

                  <Dropdown
                    className="small"
                    isOpen={minGamesChoserOpen}
                    toggle={this.toggleMinGamesChoser}
                  >
                    <DropdownToggle caret>
                      {MinGamesMap[userSettings.minGames ? userSettings.minGames : '1']}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(MinGamesMap).map(minKey => (
                        <DropdownItem
                          key={minKey}
                          onClick={() => {
                            this.selectMinGames(minKey);
                          }}
                        >
                          {MinGamesMap[minKey]}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                </Row>
                {devMode ? (
                  <Row>
                    <div className="game-type-wrapper">
                      <Row>
                        <Col xs="12">
                          <Label
                            className="game-type-input"
                            style={{ marginLeft: 28, marginBottom: 10 }}
                          >
                            <Input
                              type="checkbox"
                              onClick={this.toggleAutoMergeRooms}
                              checked={!!autoMergeRooms}
                              readOnly
                            />
                            <span className="checkmark" style={{ marginLeft: 25 }} />
                            <div className="game-type-text">
                              <span>{t('roomsTable.autoMergeRooms')}</span>
                              <div onMouseEnter={() => this.mouseEnterMergePopover()} onMouseLeave={() => this.mouseLeaveMergePopover()} className="game-type-text-info-imageWrapper">
                                <Media className="game-type-text-info-image" src={infoImg} alt="" />
                                <div className={`game-type-text-info-hover ${mergePopoverActive ? null : 'display-none'}`}>
                                  {t('roomsTable.autoMergeRoomsInfo')}
                                </div>
                              </div>
                            </div>
                          </Label>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                ) : (null)}
              <Row>
                <Button
                //  className={`start-game-button ${(activeTournament) && 'disabled'}`}
                    className={`start-game-button ${((activeTournament && !finishedTournamentLastRound) || joinedRoom) && 'disabled'}`}
                  color="link"
                //  disabled={createRoomClicked}
                  onClick={() => {
                    this.createRoomClicked();
                  }}
                  id="newGame-button"
                  onMouseEnter={this.onHover}
                  onMouseLeave={this.onHoverLeave}
                >
                  {t('newGame.startGame')}
                </Button>
                  {((activeTournament && !finishedTournamentLastRound) && !activeRoom) ? (
                  <Popover container={'div > div'} popperClassName={`popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen} target="newGame-button">
                    <PopoverBody className="popover-body">
                      <div>
                        {t('common.inTournamentMessage')}
                      </div>
                    </PopoverBody>
                  </Popover>
                ) : (null)}
              </Row>
            </div>
          )}
          <Modal
            container={'div > div'}
            isOpen={privateRoomConfirm}
            toggle={() => this.closePrivateConfirm()}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={() => this.closePrivateConfirm()}
                />
              )}
            >{t('home.confirm')}</ModalHeader>
            <ModalBody className="notification-body">
              {t('newGame.privateRoomConfirm')}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button
                color="link"
                className="btn notification-footer-button"
                onClick={this.confirmTogglePrivate}
              >
                {t('common.yes')}
              </Button>
              <Button
                color="link"
                className="btn notification-footer-button"
                onClick={() => this.closePrivateConfirm()}
              >
                {t('common.no')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            container={'div > div'}
            isOpen={!!showPrivateRoomPassword}
            toggle={() => closePrivateRoomPassword()}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={() => closePrivateRoomPassword()}
                />
              )}
            >
              {t('newGame.yourPassword')}
            </ModalHeader>
            <ModalBody className="notification-body">
              {digitsArr.map(digit => (
                <div key={`digit2-${digit.number}-${digit.index}`} className="room-password-digit">{digit.number}</div>
              ))}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button
                color="link"
                className="btn notification-footer-button"
                onClick={() => closePrivateRoomPassword()}
              >
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            container={'div > div'}
            isOpen={errorModalOpen}
            toggle={this.closeErrorModal}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={this.closeErrorModal}
                />
              )}
            >
              {errorModalHeader}
            </ModalHeader>
            <ModalBody className="notification-body">
              {errorModalText}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button
                color="link"
                className="btn notification-footer-button"
                onClick={this.closeErrorModal}
              >
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  let joinedRoom = null;
  if (state.member && state.member.joinedRooms && state.rooms && state.rooms.rooms) {
    joinedRoom = state.rooms.rooms[Object.keys(state.member.joinedRooms)[0]] ? state.rooms.rooms[Object.keys(state.member.joinedRooms)[0]] : null;
  }

  return {
    //  userSettings: state.userSettings || {},
    userSettings: state.userSettings ? (state.userSettings[state.member.uid] || state.userSettings.default || {}) : {},
    soundOn: state.userSettings.soundOn,
    //  rooms: state.rooms.rooms || {},
    lvl: state.member.level,
    uid: state.member.uid,
    activeRoom: state.member.activeRoom,
    activeTournament: state.member.activeTournament || null,
    finishedTournamentLastRound: state.member.finishedTournamentLastRound || null,
    joinedRoomPassword: state.member.joinedRoomPassword || state.rooms.joinedRoomPassword || null,
    joinedRoom,
    tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : {},
    myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
    offset: state.member.offset || 0,
    autoMergeRooms: state.member.autoMergeRooms || false,
  };
};

const mapDispatchToProps = {
  toggleNewGameParameter: toggleNewGameParam,
  setNewBet: setNewGameBet,
  setNewSpeed: setNewGameSpeed,
  setNewMinGames: setNewMinGamesFunc,
  setAutoMergeRooms: updateAutoMergeRooms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewGame));
